.tripview-page {
  .t-detail {
    margin-top: 10px;
    margin-bottom: 22px;
    > * {
      margin-bottom: 12px;
    }
  }
  .t-info {
    > * {
      padding: 22px 0;
      border-top: 1px solid #ebebeb;
    }
    h3 {
      margin-bottom: 6px;
      font-weight: 600;
    }
    .rd-desc {
      white-space: pre-line;
      button {
        color: #008489;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  ._wrap {
    padding: 15px 0;
    border-top: 1px solid #ebebeb;
    h1 {
      margin-bottom: 10px;
    }
    ._box {
      background-color: #dfe3e8;
      border-radius: 4px;
      margin-bottom: 8px;
      font-size: 15px;
      padding: 4px 10px;
      margin-right: 15px;
    }
    img {
      width: calc(33.33% - 15px);
      border-radius: 10px;
      margin-right: 15px;
      height: 113px;
      margin-bottom: 15px;
      @media (min-width: 768px) {
        width: calc(20% - 15px);
      }
    }
  }

  .itinerary {
    .item-left {
      width: 100%;
      margin: 0;
      h2 {
        margin-bottom: 0.7rem;
      }
    }
    .item-right {
      width: 100%;
      margin: 1.5rem 0;
      position: relative;
      .item {
        .v-rule {
          margin-right: 20px;
          position: relative;
          display: flex !important;
          .v-line {
            background-color: #484848 !important;
            width: 1px !important;
            position: absolute !important;
            top: 7px !important;
            bottom: 0px !important;
            &::before {
              content: "";
              position: absolute !important;
              top: -7px !important;
              left: -3px;
              background: #ffffff !important;
              border: 1px solid #484848 !important;
              border-radius: 100% !important;
              width: 7px !important;
              height: 7px !important;
            }
          }
        }
        .item-i-left {
          width: 100%;
          margin-right: 16px;
          .img-wrap {
            padding-top: 66.6667%;
            position: relative;
            .img-i-wrap {
              position: absolute;
              top: 0px;
              bottom: 0px;
              left: 0px;
              right: 0px;
            }
          }
        }
        .item-i-right {
          width: 100%;
          font-size: 1.2rem;
          .i-heading {
            font-weight: 500;
            color: #000;
            margin-top: 0.6rem;
          }
          .i-desc {
            font-size: 0.95rem;
            margin-top: 0.3rem;
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .itinerary {
      padding: 4rem 0;
      .item-left {
        width: calc(40% - 24px);
        margin-right: 24px;
        h1 {
          margin: 0;
          position: sticky;
          top: 0;
        }
      }
      .item-right {
        width: 60%;
        margin: 0;
        .item {
          .item-i-left {
            width: calc(40% - 24px);
          }
          .item-i-right {
            width: 60%;
            .i-heading {
              color: #000;
              margin-top: -6px;
            }
          }
        }
      }
    }
  }
}
