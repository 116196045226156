@import 'components/css/_theming';

.postContent {
  .validation-message {
    font-size: 12px;
    margin-top: 4px;
  }
  .bottom-panel {
    position: fixed;
    right: 0;
    bottom: 0;
    width: calc(100% - 230px);
    background-color: #ffffff;
    box-shadow: 0 3px 9px 3px rgba(0, 0, 0, 0.05);
    border-top: 1px solid #ebebeb;
    z-index: 5;
    padding: 10px 15px;
    justify-content: space-between;
    @media screen and (max-width: 991px) {
      width: calc(100%);
    }
  }
  .place-dropzone {
    height: 300px;
    border: 2px dashed rgb(235, 235, 235);
    padding: 15px;
    position: relative;
    cursor: pointer;
    outline: none;
    margin-bottom: 1rem;
    overflow: hidden;
    border-radius: 10px;
    &:hover,
    &.active {
      background-color: rgb(178, 218, 219);
    }
    .image-panel {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      cursor: default;
      button {
        position: absolute;
        right: 15px;
        top: 15px;
        z-index: 1;
        height: 30px;
        width: 30px;
        background: #fff;
        border-radius: 50%;
        line-height: 0px;
        @include box-shadow;
      }
      img {
        height: 100%;
        width: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        filter: brightness(80%);
        @keyframes imganim {
          from {
            transform: scale(1, 1);
          }
          to {
            transform: scale(1.1, 1.1);
          }
        }
        animation: imganim 5.5s infinite;
        animation-timing-function: linear;
        animation-direction: alternate;
      }
      .image-caption {
        position: absolute;
        top: 50%;
        left: 50%;
        color: #fff;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 80%;
        h1 {
          font-weight: 500;
          line-height: 3rem;
          font-size: 3rem;
        }
        p {
          font-family: 'Lobster', cursive;
          font-size: 1.5rem;
          font-weight: 500;
          margin-top: 6px;
        }
      }
    }
    .caption {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      svg {
        height: 32px;
        width: 32px;
        fill: rgb(72, 72, 72);
      }
    }
  }
  .s-box {
    background-color: #dfe3e8;
    border-radius: 4px;
    display: inline-flex;
    margin-bottom: 8px;
    font-size: 15px;
    span {
      padding: 4px 10px;
    }
    button {
      padding: 0 8px;
      font-size: 16px;
      line-height: 28px;
      &:hover {
        background-color: #c4cdd5;
      }
    }
  }
  .suggestion-list {
    background: #fff;
    box-shadow: 0 0 0 1px rgba(6, 44, 82, 0.1),
      0 2px 16px rgba(33, 43, 54, 0.08);
    border-radius: 4px;
    max-height: 315px;
    overflow-y: scroll;
    display: inline-block;
    padding: 5px 0;
    position: absolute;
    top: 42px;
    left: 0;
    margin-bottom: 90px;
    > .s-item-w {
      display: table;
      min-width: 300px;
      .s-item {
        display: block;
        width: 100%;
        padding: 10px 16px;
        text-align: left;
        background-color: #fff;
        border-radius: 3px;
        input {
          margin-right: 10px;
        }
        &:hover {
          background-color: #f5f5f5;
          cursor: pointer;
        }
      }
    }
  }
  .itinerary-btn {
    padding: 16px 24px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px;
    width: calc(100% - 53px);
    max-width: 600px;
    font-size: 18px;
    text-align: left;
    font-weight: 600;
    margin-bottom: 15px;
    i {
      margin-right: 16px;
    }
  }
  .delete-itinerary {
    float: right;
    margin-top: -18px;
  }

  .mrn-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    .spi {
      position: fixed;
      top: 50%;
      left: 0;
      width: 50px;
      height: 50px;
      right: 0;
      bottom: 0;
    }
  }
}
