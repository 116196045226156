@import '_theming';
/*
* Global CSS
*/
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html,
body {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: $font-stack;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  color: #2c3543;
  line-height: 1.5;
  background-color: #f7f7f7;
  letter-spacing: 0.3px;
  -moz-osx-font-smoothing: grayscale;
}
h1,
h2,
h3,
h4,
h5,
h6,
dl,
dd,
ol,
ul,
menu,
figure,
blockquote,
p,
pre,
form {
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}
a {
  color: inherit;
  text-decoration: none;
  @include transition;
}
section {
  padding: 4rem 0 6rem;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
button,
a {
  color: inherit;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

/*
* GRID CSS
*/
.container-full {
  width: 100vw;
  margin: 0 calc(-50vw + 50%);
}
.container-l {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 15px;
  @media (min-width: 1128px) {
    padding: 0 80px;
  }
}
.container-md {
  max-width: 940px;
  padding: 0 15px;
  margin: 0 auto;
}
.container-sm {
  max-width: 680px;
  margin: 0 auto;
  padding: 0 15px;
}
.content-card {
  -webkit-box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  background-color: #fff;
  margin-top: 20px;
  border-radius: 6px;
  padding: 15px;
  min-height: 20px;
}
.row {
  margin: 0 -15px;
}
.d-flex {
  display: -webkit-flex;
  display: flex;
}
.d-flex.flex-wrap {
  flex-wrap: wrap;
}

/*
* Typography
*/
.text-danger {
  color: $danger-color !important;
}
.text-primary {
  color: $primary-color !important;
}
.text-success {
  color: $success-color !important;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-muted {
  color: #666 !important;
}
.small {
  font-size: 13.8px;
}

/*
* List
*/
ul.neutralize {
  list-style-type: none;
  padding: 0;
}
.list-inline > li {
  display: inline-block;
}
/*
* Buttons
*/
.btn {
  display: inline-block;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.9rem 1.8rem;
  font-size: 1rem;
  border-radius: 6px;
  outline: 0;
  @include transition;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
.btn-sm {
  padding: 10px 16px;
  font-size: 0.9rem;
}
.btn-hollow {
  border: 1px solid hsla(0, 0%, 100%, 0.6);
}
.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-hollow-dark {
  border: 1px solid rgba(59, 68, 79, 0.6);
}
.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.btn-primary {
  color: #fff;
  background-color: $primary-color;
  border-color: $primary-color;
}
.btn-dark {
  color: #fff;
  background-color: $secondary-color;
  border-color: $secondary-color;
}
.btn-chromeless {
  border-width: 0;
  background: transparent;
  font-size: inherit;
  padding: 0;
  outline: 0;
  cursor: pointer;
}
.btn-block {
  display: block;
  width: 100%;
}

/*
* Badges
*/
.badge {
  border: 1px solid transparent;
  display: inline-block;
  padding: 0.3em 0.5em;
  font-size: 80%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  &.badge-danger {
    color: #fff;
    background-color: #dc3545;
  }
  &.badge-success {
    color: #fff;
    background-color: #28a745;
  }
  &.badge-warning {
    color: #fff;
    background-color: #ffb400;
  }
  &.badge-default {
    background-color: #fff;
    border-color: #ddd;
  }
}

/*
* Form
*/
.form-group {
  margin-bottom: 30px;
  input[type='text'],
  input[type='password'],
  input[type='email'],
  input[type='number'] {
    height: 45px;
  }
}
.input-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  .input-group-prepend {
    padding: 10px;
    margin-right: -1px;
    color: #495057;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-weight: 600;
  }
  > .form-control {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.form-control {
  font-family: inherit;
  outline: none;
  display: block;
  width: 100%;
  padding: 9px 12px;
  font-size: 15px;
  line-height: 1.42857143;
  background-color: #fff;
  color: #484848;
  border: 1px solid #d9d9d9;
  border-radius: 0.25rem;
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
textarea {
  min-height: 100px !important;
}
input:focus,
select:focus,
textarea:focus {
  outline: none;
  border-color: $primary-color !important;
}
.has-error .form-control {
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
button[disabled],
button[disabled]:hover {
  pointer-events: none;
  opacity: 0.25;
  cursor: default;
}
.search-input {
  background-image: url(/assets/images/search.svg);
  background-size: 16px;
  background-repeat: no-repeat;
  background-position-x: 10px;
  background-position-y: center;
  padding-left: 35px;
}
.checkbox-inline,
.radio-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  line-height: 1.6;
  cursor: pointer;
}

.checkbox-inline + .checkbox-inline,
.radio-inline + .radio-inline {
  margin-left: 10px;
}

.checkbox input[type='checkbox'],
.checkbox-inline input[type='checkbox'],
.radio input[type='radio'],
.radio-inline input[type='radio'] {
  position: absolute;
  margin: 4px 0 0;
  margin-left: -20px;
}

label.label {
  font-weight: 600 !important;
  font-size: 15px;
  margin-bottom: 8px;
  display: block;
  color: rgb(72, 72, 72);
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  background: #fff url('/assets/images/chevron-down.png') no-repeat right 12px
    center;
  padding: 7px 10% 7px 8px;
  border-radius: 0;
  width: 100%;
  color: #2d2d2d;
  border: solid 1px #999;
  outline: none;
  font-size: 14px;
  background-size: 10px;
}
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 28px;
  margin-bottom: 0;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &.round {
      border-radius: 34px;
      &::before {
        border-radius: 50%;
      }
    }
  }
  .slider:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
}

input:checked + .slider {
  background-color: #00aa5b;
}

input:focus + .slider {
  box-shadow: 0 0 1px #00aa5b;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/*
Custom radio input
*/
.custom-input-radio {
  display: none;
  &:checked ~ .custom-radio {
    .radio {
      background-color: #008489;
      border: 1px solid #008489;
      background: url(/assets/images/dot.svg);
      background-size: 22px 22px;
      background-repeat: no-repeat;
      background-position: -2px -2px;
    }
  }
}

.custom-radio {
  display: table-row;
  .radio {
    vertical-align: top;
    white-space: normal;
    position: relative;
    background-color: #fff;
    color: #008489;
    height: 20px;
    width: 20px;
    border: 1px solid rgb(176, 176, 176);
    border-radius: 50%;
  }
  .radio-label {
    display: table-cell;
    vertical-align: top;
    white-space: normal;
    padding-left: 12px;
  }
}

/*
Custom checkbox input
*/
.custom-input-checkbox {
  display: none;
  &:checked ~ .custom-checkbox {
    .checkbox {
      background-color: #000;
      border: 1px solid #000;
      background: url(/assets/images/correct.svg);
      background-size: 26px 26px;
      background-repeat: no-repeat;
      background-position: -2px -1px;
    }
  }
}

.custom-checkbox {
  display: table-row;
  .checkbox {
    display: table-cell;
    vertical-align: top;
    white-space: normal;
    position: relative;
    background-color: #fff;
    color: #008489;
    height: 24px;
    width: 24px;
    border: 1px solid rgb(176, 176, 176);
    border-radius: 4px;
  }
  .checkbox-label {
    display: table-cell;
    vertical-align: top;
    white-space: normal;
    padding-left: 12px;
  }
}

/*
* Dropdown
*/
.dropdown {
  position: relative;
  .dropdown-menu {
    display: none;
    list-style-type: none;
    position: absolute;
    transform: translate3d(0px, 38px, 0px);
    top: 0px;
    right: 0px;
    will-change: transform;
    min-width: 13rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    background-color: #fff;
    padding: 0.8rem 0;
    margin: 0.125rem 0 0;
    z-index: 1;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    &.show {
      display: block;
    }
    a,
    button {
      display: block;
      width: 100%;
      padding: 8px 16px;
      font-weight: 400;
      text-align: inherit;
      &:hover {
        background-color: #f8f9fa;
        opacity: 1;
      }
    }
  }
}

/*
* Table
*/
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  border-collapse: collapse;
  th,
  td {
    text-align: left;
    padding: 0.75rem;
    vertical-align: inherit;
    border-bottom: 1px solid #dee2e6;
    max-width: 160px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &.table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }
}
/*
* Helper classes
*/
.img-cover {
  height: 100%;
  width: 100%;
  position: static;
  object-fit: cover;
}
.page-title {
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 32px;
}
.head-panel {
  justify-content: space-between;
  margin-bottom: 30px;
  flex-wrap: wrap;
  @media (max-width: 540px) {
    > * {
      width: 100%;
    }
    .head-right {
      margin-top: 10px;
    }
  }
}
.error-box {
  box-shadow: 0 0 0 4px #fcf4f4 inset;
  padding: 14px 18px;
  border: 1px #c40000 solid;
  margin-bottom: 10px;
  svg {
    fill: #bf0711;
    position: relative;
    margin-right: 10px;
    width: 18px;
    color: #fff;
  }
}

.shadow-md {
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
}
.rounded {
  border-radius: 0.4rem;
}
.link {
  color: #007ace !important;
}
.float-right {
  float: right;
}
.clearfix {
  &::after {
    content: '';
    clear: both;
    display: table;
  }
}
/*
* http://svgicons.sparkk.fr/
*/
.svg-icon {
  width: 1em;
  height: 1em;
  path,
  polygon,
  rect {
    fill: currentcolor;
    stroke: currentColor;
    stroke-width: 0.5px;
    fill: currentcolor;
  }
  circle {
    fill: currentcolor;
    stroke: currentColor;
    stroke-width: 0.5px;
  }
}
/*
* Custom side notification
*/

.side-slide-notification {
  position: fixed;
  z-index: 1000;
  align-items: center;
  border-radius: 5px;
  color: #fff;
  left: 50%;
  padding: 15px 30px;
  top: 40px;
  transform: translate(-50%, -110px);
  padding: 18px 30px;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  align-items: center;
  @include box-shadow;
  @include transition;
  svg {
    font-size: 20px;
    margin-right: 8px;
  }
  &.show {
    transform: translate(-50%, 0);
  }
}

/*
* Loaders
*/
.spi {
  border: 2px solid $primary-color;
  border-bottom: 2px solid transparent;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  margin: 0 auto;
  background: transparent;
  -webkit-animation: spin 0.6s linear infinite;
  -moz-animation: spin 0.6s linear infinite;
  animation: spin 0.6s linear infinite;
}
.spi-light {
  border-color: #fff;
  border-bottom-color: transparent;
}
.spi-sm {
  height: 16px;
  width: 16px;
  margin: 2px;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
