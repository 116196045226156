footer{
  padding: 20px 0;
  border-top: 1px solid #ddd;
  text-align: right;
  font-size: 12px;
  justify-content: flex-end;
  .heart{
    color: #eb5e28;
    animation: hearthing 1s ease infinite;
    @keyframes hearthing {
      0% {
        transform: scale(1);
      }
      25% {
        transform: scale(0.8);
      }
      40% {
        transform: scale(1);
      }
      60% {
        transform: scale(0.8);
      }
      100% {
        transform: scale(1);
      }
    }
  }
}
