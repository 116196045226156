.mjl.modal {
  overflow-x: auto;
  overflow-y: auto;
  bottom: 0;
  left: 0;
  outline: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 90;
}

.mjl .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  width: 100%;
  max-width: 720px;
  border-radius: 5px;
  padding: 20px 20px;
  margin: 0 auto;
}

.mjl .modal-content .modal-body {
  position: relative;
}

.mjl .modal-header:after {
  content: " ";
  clear: both;
  display: table;
}

.mjl .closeModal {
  margin-right: -3px;
  float: right;
}

.modal--open {
  overflow: hidden;
}
