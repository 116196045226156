/*
* Hiding delete button while sorting
*/
body > .i-wrap button {
  display: none;
}
.trip-image {
  .i-tips {
    ul li {
      i {
        margin-right: 10px;
        margin-top: 5px;
      }
      margin-bottom: 6px;
      font-size: 13.8px;
      align-items: flex-start;
    }
  }
  .dz-err {
    margin-bottom: 15px;
  }
  .img-set {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 0.8rem;
    > :first-child {
      grid-column: 1 / span 2;
      grid-row: 1 / span 2;
    }
    .f-grid {
      grid-column: 1 / span 5;
    }
    .s-grid {
      border-color: #dfe3e8;
      .dz-caption {
        font-size: 13px;
        p {
          font-size: 13px;
        }
        button {
          font-size: 13px;
          background: transparent;
          border: 0;
          color: #007ace;
          padding: 0;
          margin: 0;
          margin-bottom: 6px;
        }
      }
    }
  }
  @media (max-width: 900px) {
    .img-set {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  @media (max-width: 500px) {
    .img-set {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  .img-dz {
    border: 2px dashed rgb(235, 235, 235);
    border-radius: 3px;
    padding: 20px 15px;
    min-height: 135px;
    position: relative;
    outline: none;
    cursor: pointer;
    &:hover,
    &.active {
      background-color: rgb(178, 218, 219);
    }
    .dz-caption {
      position: absolute;
      width: 80%;
      top: 50%;
      left: 50%;
      text-align: center;
      transform: translate(-50%, -50%);
      p {
        font-size: 18px;
      }
      button {
        margin-bottom: 12px;
      }
      svg {
        height: 32px;
        width: 32px;
        fill: rgb(72, 72, 72);
      }
    }
  }

  .err-wr {
    display: flex;
    box-shadow: inset 0 3px 0 0 #de3618, inset 0 0 0 0 transparent,
      0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
    background-color: #fdf3f0;
    border-radius: 0 0 3px 3px;
    padding: 15px;
    margin-bottom: 20px;
    .iw {
      border-radius: 50%;
      border: 3px solid #fead9a;
      margin-right: 10px;
      svg {
        fill: #bf0711;
        position: relative;
        display: block;
        width: 18px;
        color: #fff;
      }
    }
    p {
      font-weight: bold;
      margin-bottom: 5px;
      font-size: 15px;
    }
    ul {
      padding: 0;
      padding-left: 20px;
    }
  }
}

.i-wrap {
  ._out {
    cursor: grab;
    position: relative;
    width: 100%;
    border: 1px solid var(--p-divider-subdued-on-surface, #dfe3e8);
    background: #f4f6f8;
    border-radius: 3px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    ._in {
      z-index: 5;
      display: flex;
      overflow: hidden;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      border-radius: 2px;
      &::after {
        content: "";
        display: block;
        width: 100%;
        padding-bottom: 100%;
      }
      &:hover .remove-btn,
      &:active .remove-btn {
        display: block;
      }
      button.remove-btn {
        display: none;
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 8;
      }
      > img {
        position: absolute;
        z-index: 1;
        max-width: calc(100% + 1px);
        max-height: calc(100% + 1px);
      }
    }
  }
}
