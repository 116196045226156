@import 'components/css/_theming';

.nav-right {
  position: fixed;
  display: block;
  top: 0;
  right: auto;
  min-width: 230px;
  padding: 12px;
  height: 100vh;
  min-height: 100%;
  overflow-y: scroll;
  box-shadow: rgba(0, 0, 0, 0.05) -4px 0px 20px inset;
  @include transition;
  .nav-brand {
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding: 7px 0;
    margin-top: -12px;
    .img-wrap {
      background-color: $primary-color;
      height: 45px;
      width: 45px;
      border-radius: 50%;
      line-height: 55px;
      text-align: center;
      img {
        height: 24px;
      }
    }
  }
  .nav-links {
    padding: 12px 0;
    > li {
      a,
      button {
        border-width: 0;
        background: transparent;
        outline: 0;
        padding: 8px 12px;
        display: block;
        border-radius: 5px;
        font-size: 14px;
        color: rgb(118, 118, 118);
        .s-icon {
          display: inline-block;
          width: 15px;
          height: 15px;
          margin-right: 8px;
          i {
            color: $primary-color;
          }
        }

        &.active {
          color: #000;
          background-color: rgb(235, 235, 235);
          & ~ .dropdown {
            height: 100%;
          }
        }
      }
      .dropdown {
        height: 0;
        overflow: hidden;
        margin-left: 12px;
        margin-top: 8px;
        @include transition;
        a,
        button {
          color: #000;
        }
      }
    }
  }
}
._main_panel {
  float: right;
  width: calc(100% - 230px);
  padding-bottom: 5rem;
  @include transition;
  .nav-top {
    padding: 12px;
    justify-content: space-between;
    .hamburger {
      padding: 6px;
      display: none;
      svg {
        height: 1.5rem;
        width: 1.5rem;
        fill: currentColor;
        vertical-align: middle;
        transform: scaleX(-1);
      }
    }
    .nav-links {
      > li {
        > a,
        > button {
          padding: 4px 12px;
          border-radius: 5px;
          font-size: 14px;
          color: rgb(72, 72, 72);
          font-weight: 600;
          &:hover {
            background-color: hsla(0, 0%, 100%, 0.08);
          }
        }
      }
    }
  }
  ._bod_wrap {
    padding: 0 20px;
    ._bod_content {
      padding: 15px 0;
    }
  }
}

@media screen and (max-width: 991px) {
  .nav-open .nav-right {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -o-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  .nav-right {
    transform: translate3d(-230px, 0, 0);
  }
  .nav-open ._main_panel {
    width: calc(100% - 230px);
  }
  ._main_panel {
    width: 100%;
    .nav-top {
      .hamburger {
        display: block;
      }
    }
  }
}
