#bookingViewPage {
  padding-bottom: 2rem;
  .page-title {
    margin-bottom: 0.8rem;
  }
  ul {
    padding-bottom: 22px;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 22px;
    li {
      margin: 6px 0;
    }
  }
  h3 {
    margin-bottom: 0.8rem;
    font-weight: 600;
  }
}
