@import 'components/css/_theming';

.nav-top {
  .nav-avatar {
    width: 30px;
    height: 30px;
    background: $primary-color;
    color: #fff;
    margin-top: -3px;
    text-transform: capitalize;
    font-weight: 700;
    border-radius: 50%;
    margin-right: 10px;
    line-height: 30px;
    font-size: 16px;
  }
  .divider {
    border-bottom: 1px solid #ddd;
    margin: 8px 0;
  }
}
